@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

@font-face {
    font-family: "ggsans Bold";
    src: local("ggsans Bold"),
     url("./fonts/ggsans-Bold.ttf") format("truetype");
   }

   @font-face {
    font-family: "ggsans Normal";
    src: local("ggsans Normal"),
     url("./fonts/ggsans-Normal.ttf") format("truetype");
   }

body {
  color: #fff;
  font-family: "ggsans Normal", sans-serif;
  background-image: url(images/bg.jpg);
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(42, 42, 45);
  background: linear-gradient(
    0deg,
    rgba(42, 42, 45, 1) 0%,
    rgba(42, 42, 45, 1) 50%,
    rgba(42, 42, 45, 0.6) 100%
  );
}

.container {
  width: 100%;
  margin: 10% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-family: "ggsans Bold";
  font-size: 60px;
  line-height: 0.1;
  color: #78aebc;
}

h2 {
    font-family: "ggsans Normal";
    font-size: 24px;
    color: #b79bab;
}

h3 {
    font-family: "ggsans Normal";
    font-size: 18px;
    color: #cbc6ca;
}
.discord-logo > img {
    width: 20%;
    height: auto;
    border-radius: 45px;
    padding: 20px;
  }

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-family: "ggsans Bold";
    font-size: 100px;
    color: #78aebc;
  }
  h2 {
    font-family: "ggsans normal";
    font-size: 40px;
    color: #b79bab;
}

h3 {
    font-family: "ggsans Normal";
    font-size: 30px;
    color: #cbc6ca;
}
.discord-logo > img {
    width: 15%;
    height: auto;
    border-radius: 45px;
    padding: 20px;
  }
}